import React, {
  useMemo,
  useCallback,
  useEffect,
  memo,
  forwardRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Page,
  DeleteDialog,
  SmartTable,
  Search,
  SuccessDialog,
  InputLabel,
  Badge,
  withStyles,
  makeStyles,
  FormControl,
} from "../../../components";
import {
  deleteMasterRecipient,
  listMasterRecipients,
  updateMasterRecipient,
} from "../../../actions/masterRecipient";
import Actions from "./Actions";
import { getColumns } from "../../RecipientLists/Edit/Edit";
import FormDialog from "../../../components/FormDialog";
import { getMasterListRecipientHeaders } from "../../../actions/newRecipientList";
import { useTableActions } from "../../../hooks/useTableActions";

const StyledBadge = withStyles((theme) => ({
  badge: {
    position: "relative",
    transform: "scale(1) translate(10%, -10%)",
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1190,
  },
  smallCell: {
    minWidth: 150,
    maxWidth: 200,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

function getParams(queryParams) {
  const { page, itemsPerPage, search } = queryParams;
  return {
    page: page ?? 0,
    itemsPerPage: itemsPerPage ?? 20,
    search: search || "",
  };
}
const sortingArr = ["Name", "Contact", "Contact Type"];
function sortHeaders(a, b) {
  if (a.label === "Actions") {
    return 1; // Actions should come at the end, so after B: so positive number
  } if (b.label === "Actions") {
    return -1; // Actions should come at the end, so after A: so negative number
  } else if (sortingArr.includes(a.label) && sortingArr.includes(b.label)) {
    // Both are in sorting array so use position in array
    return sortingArr?.indexOf(a.label) - sortingArr.indexOf(b.label);
  } else if (sortingArr.includes(a.label)) {
    // A is in sorting array but B is not, so A should come before B, so negative number
    return -1;
  } else if (sortingArr.includes(b.label)) {
    // B is in sorting array but A is not, so A should come after B, so positive number
    return 1;
  } else {
    return a.label - b.label; // Else sort alphabetically
  }
}

const masterListColumns = "masterListColumns";
function MasterRecipientListsBase() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const {
    data: masterRecipients,
    totalCount,
    recipientHeaders,
    recipientListId,
  } = useSelector((state) =>
    state.masterListRecipients ? state.masterListRecipients : { totalCount: 0 }
  );
  const isMasterListValid =
    recipientHeaders && Boolean(Object.keys(recipientHeaders).length);
  const [selectedRecipient, setSelectedRecipient] = useState({});
  const [open, setOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [action, setAction] = useState("");
  const [recipientToDelete, setRecipientToDelete] = useState();
  const {
    onItemsPerPageChange,
    onPageChange,
    onSearchChange,
  } = useTableActions();
  const onEdit = useCallback((recipient) => {
    setSelectedRecipient(recipient);
    setOpen(!open);
  }, [setSelectedRecipient, setOpen, open]);
  const onOpenDeleteDialog = useCallback(
    (recipient) => setRecipientToDelete(recipient),
    [setRecipientToDelete]
  );
  const onCloseDeleteDialog = useCallback(() => setRecipientToDelete(null), [
    setRecipientToDelete,
  ]);

  const onConfirmEdit = async (result) => {
    setOpen(false);
    selectedRecipient.fields = result;
    await onEditRecipient(selectedRecipient);
    setAction("updated");
    setSuccessOpen(true);
  };
  const formFields = useMemo(() => {
    if (recipientHeaders && selectedRecipient._id) {
      delete recipientHeaders["ssn"];
    }
    return {
      ...recipientHeaders,
      contactType: !selectedRecipient._id
        ? {
            type: "array",
            items: {
              type: "string",
              enum: ["sms", "voice", "email", "tty"],
            },
          }
        : {},
    };
  }, [recipientHeaders, selectedRecipient]);

  const queryParams = useSelector((state) => state.params);
  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );
  const activeCompanyId = useSelector((state) => state.storage.companyId);

  const params = useMemo(
    () => getParams(queryParams, activeDivisionId, activeCompanyId),
    [queryParams, activeDivisionId, activeCompanyId]
  );

  const onDelete = useCallback(async () => {
    await dispatch(deleteMasterRecipient(recipientToDelete));
    await dispatch(listMasterRecipients(params));
    setAction("deleted");
    setSuccessOpen(true);
    onCloseDeleteDialog();
  }, [dispatch, onCloseDeleteDialog, recipientToDelete, params]);

  const formatLabels = (columns) => {
    if (columns) {
      return columns.map(({ label, ...restCol }) => {
        const splitLabel = label.replace(/([A-Z])/g, " $1");
        const titleCaseLabel =
          splitLabel.charAt(0).toUpperCase() + splitLabel.slice(1);
        return {
          ...restCol,
          label: t(titleCaseLabel),
        };
      });
    }
  };

  const columns = useMemo(() => {
    const columns = formatLabels(getColumns(recipientHeaders, t));
    if (columns && isMasterListValid) {
      columns.push({
        name: "actions",
        getter: (recipient) => (
          <Actions
            onEdit={() => onEdit(recipient)}
            onDelete={() => onOpenDeleteDialog(recipient)}
          />
        ),
        label: t("Actions"),
        sortable: false,
        align: "center",
        searchable: false,
      });
    }
    return columns;
  }, [recipientHeaders, t]);

  const columnsList = columns?.filter((col) => col.label != "Actions");
  const nonEncryptedColumnsList = columns?.filter(
    (column) => column.searchable
  );

  // Clear local storage for users with old version of columns list
  const PAGE_VERSION = "1.1.0";
  const isLocalStorageCleared = localStorage.getItem(PAGE_VERSION);
  if (!isLocalStorageCleared) {
    localStorage.removeItem(masterListColumns);
    localStorage.setItem(PAGE_VERSION, "true");
  }
  const defaultColumnsList = JSON.parse(
    localStorage.getItem(masterListColumns)
  );
  const [selectedColumns, setSelectedColumns] = useState(
    defaultColumnsList || []
  );

  const displayColumns = useMemo(() => columns
    ?.filter((col) => {
      return (
        selectedColumns?.some(
          (selectedColumn) => selectedColumn.label === col.label
        ) || col.label === "Actions"
      );
    })
    ?.sort(sortHeaders), [columns, selectedColumns]);

  const onEditRecipient = useCallback(
    async (recipient) => {
      dispatch(
        updateMasterRecipient(
          recipient._id,
          {
            recipientListId: recipient.recipientListId,
            recipient: recipient.fields,
          },
          { userId: user.id }
        )
      ).then(() => {
        dispatch(listMasterRecipients(params));
      });
    },
    [dispatch, masterRecipients]
  );

  // When params or recipientListId changes, refresh master recipients table
  useEffect(() => {
    if (recipientListId) {
      dispatch(listMasterRecipients(params));
    }
  }, [dispatch, params, recipientListId]);

  // When params change, refresh master recipient list headers
  useEffect(() => {
    dispatch(getMasterListRecipientHeaders());
  }, [dispatch, params]);

  useEffect(() => {
    if (!selectedColumns?.length && columnsList?.length) {
      setSelectedColumns(nonEncryptedColumnsList);
      localStorage.setItem(
        masterListColumns,
        JSON.stringify(nonEncryptedColumnsList)
      );
    }
  }, [selectedColumns, columnsList]);

  const onColumnsChange = (event) => {
    const newSelection = event.target.value;

    // Below check for disabled items is required as disabling through props in MenuItem component
    // doesn't prevent click events firing on empty space within menu item click
    const hasDisabledItem = newSelection.some((selectedLabel) => {
      const column = columnsList.find((col) => col.label === selectedLabel);
      return column && !column.searchable;
    });

    if (hasDisabledItem) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    const selectedCols = columnsList.filter((column) =>
      newSelection.includes(column.label)
    );

    if (selectedCols.length >= 1) {
      setSelectedColumns(selectedCols);
      localStorage.setItem(masterListColumns, JSON.stringify(selectedCols));
    }
  };

  return (
    <Page p={2} pt={0}>
      {columnsList && (
        <FormControl>
          <InputLabel variant="outlined">Display fields</InputLabel>
          <Select
            multiple
            style={{ marginBottom: 20 }}
            value={selectedColumns?.map((col) => col.label) || []}
            onChange={onColumnsChange}
            renderValue={(selected) => selected.join(", ")}
            label="Display fields"
            variant="outlined"
          >
            {columnsList.map((column) => (
              <MenuItem
                key={column.label}
                value={column.label}
                // MUI 4 doesn't seem to handle properly disabled prop here so additional safeguarding is required
                // in parent select component onChange
                disabled={!column.searchable}
              >
                <Checkbox
                  checked={selectedColumns?.some(
                    (col) => col.label === column.label
                  )}
                  disabled={!column.searchable}
                />
                <StyledBadge
                  color="secondary"
                  badgeContent="Encrypted"
                  invisible={column.searchable}
                >
                  <ListItemText primary={column.label} />
                </StyledBadge>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Search
        variant="outlined"
        size="small"
        style={{
          marginBottom: 10,
          maxWidth: 500,
        }}
        label={t("Search")}
        onSearch={onSearchChange}
        defaultValue={params.search}
        debounce={300}
        fullWidth
      />
      <SmartTable
        columns={displayColumns}
        className={classes.table}
        data={isMasterListValid ? masterRecipients : []}
        totalCount={isMasterListValid ? totalCount : 0}
        notFoundMessage={t("No master recipients found")}
        page={params.page}
        itemsPerPage={params.itemsPerPage}
        onPageChange={onPageChange}
        onItemsPerPageChange={isMasterListValid && onItemsPerPageChange}
        testId="masterRecipients"
      />
      <FormDialog
        open={open}
        resource="Recipient"
        onClose={() => setOpen(false)}
        onSave={onConfirmEdit}
        fields={formFields}
        data={selectedRecipient.fields}
      />
      <DeleteDialog
        open={Boolean(recipientToDelete)}
        onClose={onCloseDeleteDialog}
        onDelete={onDelete}
        name={recipientToDelete?.name}
        resource={t("Recipient")}
      />
      <SuccessDialog
        open={Boolean(successOpen)}
        onClose={() => setSuccessOpen(false)}
        name={selectedRecipient?.fields?.name}
        action={action}
        resource={"Recipient"}
      />
    </Page>
  );
}

export default memo(forwardRef(MasterRecipientListsBase));
