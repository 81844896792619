import React, { useState, useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TextField,
  Button,
  Grid,
  PhoneInput,
  Chip,
  IconButton,
} from "..";
import { Email, Sms, Hearing, DeleteForever } from "../../icons";

const useStyles = makeStyles((theme) => ({
  button: {
    height: "100%",
  },
  buttonContainer: {
    marginLeft: "auto",
  },
}));

const icons = {
  email: Email,
  sms: Sms,
  voice: Hearing,
  tty: Hearing,
};

function Test({ onSendTest, activeDivisionId, ...rest }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [testFields, setTestFields] = useState({});
  const [testChips, setTestChips] = useState([]);

  const onChange = useCallback(
    (ev) => setTestFields({ ...testFields, [ev.target.name]: ev.target.value }),
    [setTestFields, testFields]
  );

  const onDeleteTestChip = useCallback(
    (testChip) => setTestChips(testChips.filter((chip) => chip !== testChip)),
    [testChips, setTestChips]
  );

  const onAddTestChip = useCallback(
    (type, value) => {
      if (value && 3 <= value.length) {
        setTestFields({ ...testFields, [type]: "" });
        if (
          !testChips.some(
            (testChip) => testChip.value === value && testChip.type === type
          )
        ) {
          setTestChips([...testChips, { type, value }]);
        }
      }
    },
    [testFields, testChips, setTestChips, setTestFields]
  );

  const onClearTestChips = useCallback(() => setTestChips([]), [setTestChips]);

  const onPressEnter = useCallback(
    (ev) => {
      if (ev.key === "Enter") {
        const type = ev.target.name;
        const value = testFields[type];
        onAddTestChip(type, value);
      }
    },
    [testFields, onAddTestChip]
  );

  const onBlur = useCallback(
    (ev) => {
      const type = ev.target.name;
      const value = testFields[type];
      onAddTestChip(type, value);
    },
    [testFields, onAddTestChip]
  );

  const onSendTestClick = useCallback(
    async () => setTestChips(await onSendTest(testChips)),
    [onSendTest, testChips, setTestChips]
  );

  return (
    <Paper {...rest} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md>
          <TextField
            type="email"
            name="email"
            value={testFields.email}
            onChange={onChange}
            label={t("Email")}
            variant="outlined"
            className={classes.input}
            size="small"
            onBlur={onBlur}
            onKeyUp={onPressEnter}
            fullWidth
            data-test-id="approverspage-test-email"
          />
        </Grid>
        <Grid item xs={12} sm={6} md>
          <PhoneInput
            inputProps={{
              name: "sms",
              onBlur,
              onKeyUp: onPressEnter,
            }}
            className={classes.input}
            value={testFields.sms || "+1"}
            onChange={(value) => onChange({ target: { name: "sms", value } })}
            label={t("SMS")}
            variant="outlined"
            country="us"
            countryCodeEditable={false}
            size="small"
            fullWidth
            data-test-id="approverspage-test-sms"
          />
        </Grid>
        <Grid item xs={12} sm={6} md>
          <PhoneInput
            inputProps={{
              name: "voice",
              onBlur,
              onKeyUp: onPressEnter,
            }}
            value={testFields.voice || "+1"}
            onChange={(value) => onChange({ target: { name: "voice", value } })}
            label={t("Voice")}
            variant="outlined"
            country="us"
            countryCodeEditable={false}
            size="small"
            fullWidth
            data-test-id="approverspage-test-voice"
          />
        </Grid>
        {/* Removing tty test call option as per ECS-2165*/}
        {(1 === 0) && <Grid item xs={12} sm={6} md>
          <PhoneInput
            inputProps={{
              name: "tty",
              onBlur,
              onKeyUp: onPressEnter,
            }}
            value={testFields.tty || "+1"}
            onChange={(value) => onChange({ target: { name: "tty", value } })}
            label={t("TTY")}
            variant="outlined"
            country="us"
            countryCodeEditable={false}
            size="small"
            fullWidth
            data-test-id="approverspage-test-tty"
          />
        </Grid>}
        <Grid item className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={onSendTestClick}
            disabled={testChips.length === 0}
            data-test-id="approverspage-test-button"
          >
            {t("Send Test")}
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="center"
        data-test-id="approverspage-test-chips"
      >
        {testChips.map((testChip) => {
          const Icon = icons[testChip.type];
          return (
            <Grid item key={testChip.value}>
              <Chip
                color={testChip.error ? "secondary" : "default"}
                icon={<Icon />}
                label={testChip.value}
                onDelete={() => onDeleteTestChip(testChip)}
              />
            </Grid>
          );
        })}
        {Boolean(testChips.length) && (
          <Grid item className={classes.buttonContainer}>
            <IconButton
              color="secondary"
              onClick={onClearTestChips}
              data-test-id="approverspage-test-chips-clear-button"
            >
              <DeleteForever />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

export default memo(Test);
