import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  List as ListIcon,
  Send,
  Today,
  Room,
  PhoneCallback,
  BarChart,
  Description,
  ExitToApp,
  Book,
  Colorize,
  Home,
  NotInterested,
  ListRounded,
  FormatColorText,
  SupervisorAccount,
  FolderOpenTwoTone,
} from "../../icons";
import { ResponsiveDrawer, List, useTheme } from "../../components";
import { logout } from "../../actions/user";
import SideNavItem from "./SideNavItem";
import useAuthorized, {
  APPROVER_LIST_PERMISSION,
  CALENDAR_LIST_PERMISSION,
  MAP_CREATE_PERMISSION,
  MAP_LIST_PERMISSION,
  RECIPIENTLIST_CREATE_PERMISSION,
  RECIPIENTLIST_LIST_PERMISSION,
  RECIPIENTLIST_GET_PERMISSION,
  REPORT_LIST_PERMISSION,
  SEED_LIST_PERMISSION,
  TEMPLATE_CREATE_PERMISSION,
  TEMPLATE_LIST_PERMISSION,
  CAMPAIGN_LIST_PERMISSION,
  CAMPAIGN_CREATE_PERMISSION,
  SUPPRESSION_CREATE_PERMISSION,
  SUPPRESSION_LIST_PERMISSION,
  INBOUND_CREATE_PERMISSION,
  INBOUND_LIST_PERMISSION,
  KEYWORD_LIST_PERMISSION,
  KEYWORD_CREATE_PERMISSION,
  ADMIN_GET_PERMISSION,
  ADMIN_CREATE_PERMISSION,
} from "../../hooks/useAuthorized";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#262F3D",
  },
}));

function SideNav({ children, ...rest }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const onLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const canListRecipientLists = useAuthorized(RECIPIENTLIST_LIST_PERMISSION);
  const canGetRecipientLists = useAuthorized(RECIPIENTLIST_GET_PERMISSION);
  const canCreateRecipientLists = useAuthorized(
    RECIPIENTLIST_CREATE_PERMISSION
  );
  const hasAccessToRecipientListFeature =
    canListRecipientLists || canCreateRecipientLists;

  const canListReports = useAuthorized(REPORT_LIST_PERMISSION);
  const canListCalendar = useAuthorized(CALENDAR_LIST_PERMISSION);

  const canListTemplates = useAuthorized(TEMPLATE_LIST_PERMISSION);
  const canCreateTemplates = useAuthorized(TEMPLATE_CREATE_PERMISSION);
  const hasAccessToTemplateFeature = canCreateTemplates || canListTemplates;

  const canListApprovers = useAuthorized(APPROVER_LIST_PERMISSION);
  const canListSeeds = useAuthorized(SEED_LIST_PERMISSION);

  const canListCampaign = useAuthorized(CAMPAIGN_LIST_PERMISSION);
  const canCreateCampaign = useAuthorized(CAMPAIGN_CREATE_PERMISSION);
  const hasAccessToCampaignFeature = canListCampaign || canCreateCampaign;

  const canListInbound = useAuthorized(INBOUND_LIST_PERMISSION);
  const canCreateInbound = useAuthorized(INBOUND_CREATE_PERMISSION);
  const canListMaps = useAuthorized(MAP_LIST_PERMISSION);
  const canCreateMaps = useAuthorized(MAP_CREATE_PERMISSION);

  const canListSuppression = useAuthorized(SUPPRESSION_LIST_PERMISSION);
  const canCreateSuppression = useAuthorized(SUPPRESSION_CREATE_PERMISSION);
  const hasAccessToSuppressionFeature = canListSuppression;
  // || canCreateSuppression;

  const {
    vendorConfig: {
      createRecipientListMap,
      createRecipientListMasterDate,
      advancedApproverManagement = false,
      enableInbound,
    },
    customConfig,
  } = useSelector((state) => state.eonsConfig);
  const hasAccessToInboundFeature = enableInbound && (canListInbound || canCreateInbound);

  const customNewListConfig = customConfig ? customConfig.newListConfig : null;
  const { masterList: customConfigHasMasterList } = customNewListConfig
    ? JSON.parse(customNewListConfig)
    : false; //In case no customConfig

  const hasAccessToMapFeature =
    (canListMaps || canCreateMaps) && createRecipientListMap;
  const canUseMasterData =
    createRecipientListMasterDate &&
    customConfigHasMasterList &&
    (canListRecipientLists || canGetRecipientLists);
  const hasAccessToAdvancedApproverManagement = advancedApproverManagement;

  const hasKeywordListPermission = useAuthorized(KEYWORD_LIST_PERMISSION);
  const hasKeywordCreatePermission = useAuthorized(KEYWORD_CREATE_PERMISSION);
  const hasKeywordManagementRight =
    hasKeywordListPermission || hasKeywordCreatePermission;

  const hasAdminListPermission = useAuthorized(ADMIN_GET_PERMISSION);
  const hasAdminCreatePermission = useAuthorized(ADMIN_CREATE_PERMISSION);
  const isEonsAdmin = hasAdminListPermission || hasAdminCreatePermission;
  // const isEonsAdmin = useSelector(
  //   (state) => state.eonsConfig?.vendorConfig?.eonsAdmin
  // );

  return (
    <ResponsiveDrawer
      {...rest}
      width={theme.custom.sidebarWidth}
      content={children}
      className={classes.root}
    >
      <List>
        <SideNavItem
          key="homepage"
          link="/homepage"
          text={t("Homepage")}
          testId={"homepage"}
          icon={Home}
        />
        {hasAccessToCampaignFeature && (
          <SideNavItem
            key="campaigns"
            link="/campaigns"
            text={t("Campaigns")}
            testId={"campaigns"}
            icon={Send}
          />
        )}
        {hasAccessToInboundFeature && (
          <SideNavItem
            key="inbound"
            link="/inbound"
            text={t("Inbound")}
            testId={"inboundCampaigns"}
            icon={PhoneCallback}
          />
        )}
        {hasAccessToRecipientListFeature && (
          <SideNavItem
            key="recipient-lists"
            link="/recipient-lists"
            text={t("Lists")}
            testId={"recipientLists"}
            icon={ListIcon}
          />
        )}
        {canUseMasterData && (
          <SideNavItem
            key="master-recipients"
            link="/master-recipients"
            text={t("Master List")}
            testId={"masterRecipients"}
            icon={ListRounded}
          />
        )}
        {hasAccessToTemplateFeature && (
          <>
            <SideNavItem
              key="master-templates"
              link="/master-templates"
              text={t("Templates")}
              testId={"templates"}
              icon={Description}
            />
            <SideNavItem
              key="foldersTemplates"
              link="/foldersTemplates"
              text={t("Folders")}
              testId={"foldersTemplates"}
              icon={FolderOpenTwoTone}
            />
          </>
        )}
        {canListReports && (
          <SideNavItem
            key="reports"
            link="/reports"
            text={t("Reports")}
            testId={"reports"}
            icon={BarChart}
          />
        )}
        {hasAccessToSuppressionFeature && (
          <SideNavItem
            key="suppression?type=sms"
            link="/suppression?type=sms"
            text={t("Suppression")}
            testId={"suppression"}
            icon={NotInterested}
          />
        )}
        {hasKeywordManagementRight && (
          <SideNavItem
            key="keyword"
            link="/keyword"
            text={t("Keyword")}
            testId={"keyword"}
            icon={FormatColorText}
          />
        )}
        {hasAccessToMapFeature && (
          <SideNavItem
            key="map"
            link="/map"
            text={t("Map")}
            testId={"map"}
            icon={Room}
          />
        )}
        {canListCalendar && (
          <SideNavItem
            key="calendar"
            link="/calendar"
            text={t("Calendar")}
            testId={"calendar"}
            icon={Today}
          />
        )}
        {hasAccessToAdvancedApproverManagement === false
          ? canListApprovers && (
              <SideNavItem
                key="approvers"
                link="/approvers"
                text={t("Approvers")}
                testId={"approvers"}
                icon={Book}
              />
            )
          : null}
        {canListSeeds && (
          <SideNavItem
            key="seeds"
            link="/seeds"
            text={t("Seeds")}
            testId={"seeds"}
            icon={Colorize}
          />
        )}
        {isEonsAdmin && (
          <SideNavItem
            key={"Management"}
            text={t("Management")}
            testId={"admin"}
            icon={SupervisorAccount}
            submenu={[
              isEonsAdmin && (
                <SideNavItem
                  key="admin"
                  link="/admin"
                  text={t("Campaign")}
                  testId={"campaign"}
                  icon={Send}
                  subMenuStyle={true}
                />
              ),
            ]}
          />
        )}
      </List>
      <List>
        <SideNavItem
          key={"logout"}
          onClick={onLogout}
          text={t("Log Out")}
          testId={"logout"}
          icon={ExitToApp}
        />
      </List>
    </ResponsiveDrawer>
  );
}

export default memo(SideNav);
